<template>
  <div
    style="position: relative; height: 100%"
    v-touch.prevent="{
      move: swipeMove,
      end: swipeEnd,
    }"
  >
    <div
      class="refresh-symbol"
      :style="{
        top: `${refreshTop}px`,
        transform: `rotateZ(${(refreshTop - 25) * 3}deg)`,
        opacity: refreshTop === 0 ? 0 : 1,
      }"
    >
      <v-icon color="primary" x-large>mdi-refresh</v-icon>
    </div>

    <slot />
  </div>
</template>
<style lang="scss" scoped>
.refresh-symbol {
  position: absolute;
  // z-index: 1;
  transition: top 0.3s, transform 0.9s, opacity 0.2s;
  margin: auto;
  left: 0;
  right: 0;
  width: fit-content;
}
</style>
<script>
const REFRESH_MAX = 100;
export default {
  props: {
    disabled: Boolean,
    refreshMin: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      refreshTop: 0,
    };
  },
  methods: {
    setRefreshPosition(val) {
      if (this.refreshTop + val < this.refreshMin) {
        this.refreshTop = this.refreshMin;
        return;
      }
      if (this.refreshTop + val > REFRESH_MAX) {
        this.refreshTop = REFRESH_MAX;
        return;
      }
      this.refreshTop += val;
    },
    swipeEnd() {
      if (this.disabled || window.scrollY) return;
      if (this.refreshTop > 50) this.$emit('refresh');
      this.refreshTop = this.refreshMin;
    },
    swipeMove(e) {
      if (this.disabled || window.scrollY) return;
      const { touchstartY, touchmoveY } = e;

      this.setRefreshPosition(touchmoveY - touchstartY);
    },
  },
};
</script>
