var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"touch",rawName:"v-touch.prevent",value:({
    move: _vm.swipeMove,
    end: _vm.swipeEnd,
  }),expression:"{\n    move: swipeMove,\n    end: swipeEnd,\n  }",modifiers:{"prevent":true}}],staticStyle:{"position":"relative","height":"100%"}},[_c('div',{staticClass:"refresh-symbol",style:({
      top: `${_vm.refreshTop}px`,
      transform: `rotateZ(${(_vm.refreshTop - 25) * 3}deg)`,
      opacity: _vm.refreshTop === 0 ? 0 : 1,
    })},[_c('v-icon',{attrs:{"color":"primary","x-large":""}},[_vm._v("mdi-refresh")])],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }