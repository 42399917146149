<template>
  <span v-if="showText" @click="copyText(text)" style="cursor: pointer">{{ text }}</span>
</template>

<script>
export default {
  name: 'CopyableText',
  data() {
    return {};
  },
  methods: {
    async copyText(text) {
      if (navigator.clipboard !== undefined) {
        // Chrome et.al except IE
        await navigator.clipboard.writeText(text);
      } else if (window.clipboardData) {
        // IE only
        window.clipboardData.setData('Text', text);
      }

      this.$store.commit('setNotification', {
        color: 'success',
        text: this.successMessage ?? 'Copied to clipboard',
        showing: true,
        timeout: 4000,
      });
    },
  },
  props: {
    text: String,
    showText: {
      type: Boolean,
      default() {
        return true;
      },
    },
    successMessage: String,
  },
};
</script>
