<template>
  <v-dialog v-model="isOpen" width="500px" content-class="upload-dialog" persistent>
    <v-btn icon class="close-button" @click="isOpen = false" :disabled="!canClose">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <upload-file
      v-if="isOpen"
      @uploading="onUploading"
      @close="isOpen = false"
      origin="patientPortal"
    />
  </v-dialog>
</template>
<style lang="scss" scoped>
::v-deep .upload-dialog {
  border-radius: 24px !important;
  position: relative;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin: 16px;
}
</style>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      canClose: true,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onUploading(bool) {
      this.canClose = !bool;
    },
  },
  components: {
    UploadFile: () => import('./UploadFile.vue'),
  },
};
</script>
