<template>
  <div class="file-item--container mb-4 py-2 py-sm-4 px-2 px-sm-4 mx-n4 mx-sm-0">
    <!-- paywall -->
    <div
      class="paywall__overlay d-flex flex-column align-center justify-center pa-4 pa-md-0"
      v-if="paywalled"
    >
      <div class="mb-1 mb-md-0">Payment is required to view this file.</div>

      <invoice-payment-button
        v-if="!isBillingDisabled"
        :text="'Pay now'"
        :invoiceUrl="file.invoice.hostedInvoiceUrl"
        color="white"
        :small="$vuetify.breakpoint.smAndDown"
        :fileName="file.displayName || file.fileName"
        @refresh="$emit('refresh')"
      />
    </div>
    <v-icon class="mr-4">mdi-file</v-icon>
    <div class="d-flex flex-column justify-center">
      <div>{{ file.displayName || file.fileName }}</div>
      <div class="subtitle">{{ getFileTime(file) }}</div>
    </div>
    <div class="h-100 d-flex align-center">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            @click="viewFile(file)"
            v-on="on"
            v-bind="attrs"
            v-if="!paywalled"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </template>
        <span>{{ $t('patientPortal.files.tooltip.view') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            @click="downloadFile(file)"
            v-on="on"
            v-bind="attrs"
            v-if="!paywalled"
            ><v-icon>mdi-download</v-icon></v-btn
          >
        </template>
        <span>{{ $t('patientPortal.files.tooltip.download') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../styles/patient-portal.scss';
@import '../../styles/utility.scss';
.file-item--container {
  border: 1px solid rgba(0, 93, 223, 0.1);
  background-color: white;
  display: grid;
  max-width: 600px;
  grid-template-columns: min-content auto min-content;
  border-radius: 16px;
  position: relative;
  &:hover {
    border: 1px solid var(--v-primary-base) !important;
  }
  .subtitle {
    font-size: 0.6em;
  }

  .paywall__overlay {
    position: absolute;
    border-radius: inherit;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    color: white;
    font-size: 0.7em;
    @include md {
      font-size: 0.9em;
    }
  }
}
</style>
<script>
import GET_URL_FOR_FILE from '../../graphql/Query/GetUrlForFile.gql';
import { PRETTY_DATE_FORMAT } from '../../constants/moment';

export default {
  props: {
    file: Object,
  },
  components: {
    InvoicePaymentButton: () => import('./InvoicePaymentButton.vue'),
  },
  computed: {
    paywalled() {
      if (!this.file.invoice) return false;
      const { status } = this.file.invoice;
      return status !== 'void' && status !== 'paid';
    },
    isBillingDisabled() {
      return this.$store.getters['patientPortal/isBillingDisabled'];
    },
  },
  methods: {
    getFileTime(file) {
      return this.$store.getters.toTimezone(file.createdAt).format(PRETTY_DATE_FORMAT);
    },
    async downloadFile(item) {
      this.loading = true;

      const url = await this.getFileUrl(item);

      const response = await fetch(url);

      const blob = await response.blob();
      console.log(blob);

      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = item.fileName;

      // Necessary for firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
      this.loading = false;
    },
    async getFileUrl(item) {
      const { data } = await this.$apollo.query({
        query: GET_URL_FOR_FILE,
        variables: {
          providerId: this.$store.getters['patientPortal/providerId'],
          fileId: item.id,
        },
        fetchPolicy: 'no-cache',
      });
      return data.getUrlForFile;
    },
    async viewFile(item) {
      this.loading = true;

      const url = await this.getFileUrl(item);
      const a = document.createElement('a');
      a.setAttribute('download', item.fileName);
      a.setAttribute('target', '_blank');
      a.setAttribute('href', url);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.loading = false;
    },
  },
};
</script>
